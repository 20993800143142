<template>
  <div>
    <request-quote></request-quote>
  </div>
</template>

<script>
import RequestQuote from '@/components/RequestQuote.vue'

export default {
  name: "Quote",
  components: { RequestQuote },
}
</script>